import { fetchAPI } from '../fetchAPI';

interface UserMetaObjProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export async function saveUserDetails(userMetaObj: UserMetaObjProps, token: string) {
  const userDetails = await fetchAPI(`
    mutation SaveUserDetails (
      $id: ID!,
      $firstName: String!,
      $lastName: String!,
      $email: String!
    ) {
      updateUser(
        input: {
          id: $id,
          firstName: $firstName,
          lastName: $lastName, 
          email: $email
        }
      ) {
        user {
          email
          firstName
          lastName
          id
        }
      }
    }
  `, {
    variables: {
      ...userMetaObj
    }
  }, token);

  return userDetails?.updateUser?.user;
}
