import { fetchAPI } from '../fetchAPI';

export async function getPage(slug: string, token: string) {
  return fetchAPI(`
    query Page($id: ID!, $idType: PageIdType!) {
      page(
        id: $id,
        idType: $idType 
      ) {
        title
        content
        eventDates {
          startEventDate
          endEventDate
        }
      }
    }
  `, {
    variables: {
      id: slug,
      idType: 'URI'
    }
  }, token);
};
