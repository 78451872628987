import { useContext } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import PageContent from 'components/PageContent/PageContent';

interface Props extends RouteComponentProps {
  path: string;
  exact?: boolean;
  key?: string;
}

const PrivateRoute = ({ path, ...rest }: Props) => {
  const { token } = useContext(authContext);
  const page = path.replace('/', '');
  return (
    <Route
      { ...rest }
      render={({ location }) => {
        if (token) return <PageContent page={ page } />;
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }}
    />
  );  
};

export default withRouter(PrivateRoute);
