import { fetchAPI } from '../fetchAPI';

export async function loginUser(username: string, password: string) {
  const user = await fetchAPI(`
    mutation LoginUser(
      $username: String!,
      $password: String!
    ) {
      login(
        input: {
          username: $username,
          password: $password
        }
      ) {
        authToken
        refreshToken
        user {
          id,
          firstName,
          lastName,
          name,
          username,
          email,
          jwtAuthExpiration,
          roles {
            edges {
              node {
                name
                id
                displayName
                isRestricted
              }
            }
          }
        }
      }
    }
  `, {
    variables: {
      username,
      password
    }
  });

  return user.login;
};
