import { useState, useEffect } from 'react';
import {
  withRouter, Switch, Route, Link
} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { getNavItems } from 'services/menu/getNavItems';
import ProvideAuth from 'components/ProvideAuth/ProvideAuth';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import PageContent from 'components/PageContent/PageContent';
import { Header } from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { Home } from 'components/Home/Home';
import { Login } from 'components/Login/Login';
import { Logout } from 'components/Logout/Logout';
import { Profile } from 'components/Profile/Profile';
import { Preview } from 'components/Preview/Preview';
import { Register } from 'components/Register/Register';

interface NavRoute {
  node: {
    label: string,
    path: string,
    parentId: string | null,
    childItems: {
      edges: [
        {
          node: {
            label: string,
            path: string
          }
        }
      ]
    }
  }  
}

const App = () => {
  const [navRoutes, setNavRoutes] = useState<NavRoute[] | null>(null);

  useEffect(() => {
    let cancelRequest = false;
    const fetchNavMenu = async () => {
      try {
        if (cancelRequest) return;
        const navItems = await getNavItems();
        setNavRoutes(navItems.edges.map((navItem: NavRoute) => navItem))
      } catch (err) {
        if (cancelRequest) return;
        console.error(new Error(`${err}`))
      }
    }
    fetchNavMenu();
    return () => { cancelRequest = true; };
  }, []);

  const formatNavRoutes = () => {
    if (!navRoutes?.length) return;

    const navRoutesFormatted = navRoutes
      .map((navRoute: NavRoute) => {
        const { label, path, childItems } = navRoute.node;
        const formattedPath = !label.includes('Home') ? path.replace('/wp', '') : '/';
        return (
          <li key={ label } className='Header__Item'>
            <Link to={ formattedPath }>{ label }</Link>
            { !!childItems.edges.length && (
              <ul>
                { childItems.edges.map(childItem => {
                  const { label, path } = childItem.node;
                  if (label !== 'Recordings 2020' && label !== 'QA 2020') {
                    return (
                      <li key={ label }>
                        <HashLink smooth to={ path }>{ label }</HashLink>
                      </li>
                    );
                  }
                  return (
                    <li key={ label }>
                      <Link to={ path.replace('/wp', '') }>{ label }</Link>
                    </li>
                  );
                })}
              </ul>
            ) }
          </li>
        );        
      });

    return navRoutesFormatted;
  };

  return (
    <ProvideAuth>
      { !!navRoutes?.length && (
        <div className='container'>
          <Header navItemsFormatted={ formatNavRoutes() } />
          <main>
            <Switch>
              { navRoutes
                .filter(navRoute => !navRoute.node.label.includes('Home'))
                .map(navRoute => {
                  const { label, path } = navRoute.node;
                  return (             
                    <PrivateRoute
                      key={ label }
                      exact
                      path={ `${path.replace('/wp', '')}` } />
                  );
              }) }
              <Route exact path='/' component={ () => (
                <PageContent page='/' noMarkup>
                  <Home />
                </PageContent>
              ) } />
              <Route exact path='/login' component={Login} />
              <Route exact path='/logout' component={Logout} />
              <Route exact path='/my-account' component={Profile} />
              <Route exact path='/register' component={ () => (
                <PageContent page='register' noMarkup>
                  <Register />
                </PageContent>
              ) } />
              <Route exact path='/preview/:pageId' component={ Preview } />              
              <Route path='*' component={() => <PageContent page='pagenotfound' />} />
            </Switch>
          </main>
          <Footer />
        </div>
      ) }
    </ProvideAuth>
  );
};

export default withRouter(App);
