import { fetchAPI } from '../fetchAPI';

export async function getNavItems() {
  const data = await fetchAPI(`
    query {
      menuItems (
        first: 1000,
        where: {
          location: PRIMARY,
          parentId: "null"
        }
      ) {
        edges {
          node {
            label
            path
            parentId
            childItems {
              edges {
                node {
                  label
                  path
                }
              }
            }
          }
        }
      }
    }    
  `);

  return data?.menuItems;
}
