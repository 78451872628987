import { useContext, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import Logo from 'assets/images/INL-Logo_Left-White.webp';
import hamburger from 'assets/images/hamburger.jpg';
import './Header.scss';

interface Props {
  navItemsFormatted: ReactElement[] | undefined;
}

export const Header = ({ navItemsFormatted }: Props) => {
  const { user } = useContext(authContext);

  return (
    <header className="Header">
      <div className="Header__Logo">
        <a href='https://inl.gov' target="_blank">
          <img src={Logo} alt='Idaho National Laboratory' />
        </a>
        <p>Nuclear Science and Technology</p>
      </div>
      <button className="hamburger">
        <img style={{ width:"30px" }} src={hamburger} alt='Menu' />
      </button>
      <ul>
        { navItemsFormatted }
        { user && <li><Link to='/my-account'>My Account</Link></li> }
        { !user && <li><Link to='/login'>Login</Link></li> }
        { user && <li><Link to='/logout'>Logout</Link></li> }
      </ul>
    </header>    
  );
}
