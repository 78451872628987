import { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { PageContentContext } from 'components/PageContent/PageContent';
import { formatEventDateDisplay } from 'utils/formatEventDateDisplay';
import NST12 from 'assets/images/NST1-2.webp';
import NST23 from 'assets/images/NST2-3.webp';
import NST31 from 'assets/images/NST3-1.webp';
import Strategic1 from 'assets/images/Strategic1.webp';
import INLMap from 'assets/images/INLMap.webp';
import './Home.scss';

export const Home = () => {
  const { eventDates } = useContext(PageContentContext);
  const { startEventDate, endEventDate } = eventDates;

  const eventDateText = formatEventDateDisplay(startEventDate, endEventDate);

  return (
    <>
      <section className='Home__Container'>
        <h1 className='Home__Headline'>
          Nuclear Science and Technology
          <br />
          Strategic Advisory Committee
          <br />
          Virtual Meeting
        </h1>
        <div className='Home__EventInfo'>
          <p>{ eventDateText }</p>
          <Link to='/agenda1' className='Home__Button'>Review Meeting</Link>
        </div>
      </section>
      <div className='Home__Block'></div>
      <section className='Home__Statement'>
        <div className='Home__Statement__Item'>
          <img alt='Out Vision' src={NST31} />
          <p className='Home__Statement__Item__Title lightblue'>Our Vision</p>
          <p>Change the world’s energy future and secure our critical infrastructure.</p>
        </div>
        <div className='Home__Statement__Item'>
          <img alt='Our Mission' src={NST12} />
          <p className='Home__Statement__Item__Title green'>Our Mission</p>
          <p>Discover, demonstrate and secure innovative nuclear energy solutions, clean energy options and critical infrastructure.</p>
        </div>
        <div className='Home__Statement__Item'>
          <img alt='Out Values' src={NST23} />
          <p className='Home__Statement__Item__Title blue'>Our Values</p>
          <p>Excellence, integrity, ownership, teamwork, inclusion and safety.</p>
        </div>
      </section>
      <section className='Home__Outro'>
        <div className='Home__Outro__Quote'>
          <figure style={{width: "50%",marginRight:"20%"}}>
            <blockquote>
              <p>"INL’s world-leading engineering research abilities place it in an exceptionally well-defined position to advance U.S. nuclear energy technology competitiveness."</p>
            </blockquote>
            <figcaption>- Strategic Plan and Implementing Framework 2020-2025</figcaption>
          </figure>
          <div>
            <a
               href="https://nstdirectorate.azureacc.inl.gov/wp/wp-content/uploads/2021/09/NST-Strategic-Plan-2020.pdf"
               target="_blank"
            >
              <img alt='Strategic Plan and Implementing Framework 2020-2025' src={Strategic1} />
            </a>
            </div>
        </div>
        <div className='Home__Outro__Map'>
          <div>
            <p>Meeting Dates</p>
            <HashLink smooth to='/agenda1'>{ eventDates.startEventDate }</HashLink>
            <HashLink smooth to='/agenda1'>{ eventDates.endEventDate }</HashLink>
          </div>
          <img alt='INL Map' src={INLMap}
            style={{
              width: '100%'
            }}
          />
        </div>
      </section>
    </>
  );
}
