import { useState } from 'react';
import { loginUser } from '../services/auth/login';
import ProvideAuthObject from '../types/ProvideAuthObject';

export const provideAuthInitProps = () => {
  const baseProps = {
    isLoading: false,
    error: null,
    login: () => {},
    logout: () => {}
  };

  const storedAuthInfoStr = localStorage.getItem('inl-user');
  if (storedAuthInfoStr) {
    const storedAuthInfo = JSON.parse(storedAuthInfoStr);
    return ({
      ...baseProps,
      ...storedAuthInfo
    });
  }

  return ({
    ...baseProps,  
    token: '',
    user: null,
  });
};


const useProvideAuth = () => {
  const [authInfo, setAuthInfo] = useState<ProvideAuthObject>(provideAuthInitProps());

  const login = async (username: string, password: string): Promise<void> => {
    try {
      setAuthInfo({ ...authInfo, isLoading: true });

      const data = await loginUser(username, password);
      const updatedAuthInfo = {
        ...authInfo,
        isLoading: false,
        error: null,
        token: data.refreshToken,
        user: data.user
      };
      
      // make auth & user (including PII) info available to components via context
      setAuthInfo(updatedAuthInfo);
      
      // only expose user id & token in localStorage
      localStorage.setItem('inl-user', JSON.stringify({
        ...updatedAuthInfo,
        user: data.user.id
      }));
    } catch(err) {      
      const error = new Error(`${err}`);
      
      setAuthInfo({
        ...authInfo,
        isLoading: false,
        error
      });
    }
  };

  const logout = () => {
    localStorage.removeItem('inl-user');
    setAuthInfo(provideAuthInitProps());
  };

  return {
    ...authInfo,
    login,
    logout,
  };
};

export default useProvideAuth;