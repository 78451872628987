import { useEffect, useState, useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { getDraftPage } from '../../services/page/getDraftPage';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import './Preview.scss';

export const Preview = () => {
  const { token } = useContext(authContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | boolean>(false);
  const [markup, setMarkup] = useState<string | undefined>();

  const { pageId } = useParams<{ pageId: string }>();

  useEffect(() => {
    let cancelRequest = false;
    if (token) {
      const getData = async () => {      
        const d = await getDraftPage(pageId, token);
        if (cancelRequest) return;
        if (d?.page?.content) {
          setIsLoading(false);
          setMarkup(d.page.content)
        } else {
          setIsLoading(false);
          setMarkup("<div class='View__Message'>You do not have permissions to view this draft.</div>");
        }
      };
      
      try {
        setIsLoading(true);
        getData();
      } catch(err) {
        if (cancelRequest) return;
        const error = new Error(`${err}`);
        setIsLoading(false);
        setRequestError(error.message);
      }
      
    }    
    return () => { cancelRequest = true; };
  }, [token, pageId]);

  const html = { __html: markup || '' };

  if (!token) return <Redirect to='/login' />;

  return (
    <>
      { isLoading && <div className="View View__Loading">Loading...</div> }
      { !isLoading && requestError && <p>Error retrieving requested page.</p> }
      { !isLoading && !!markup && (
        <section className='Preview'>
          <div
            className='Preview__Content'
            dangerouslySetInnerHTML={ html }
          />
          <p className='Preview__Banner'>
            Viewing Draft Page
          </p>
        </section>
      ) }
    </>
  );
};
