import { fetchAPI } from '../fetchAPI';

export async function getUserDetails (id: string, token: string) {
  const getUserDetails = await fetchAPI(`
    query user($id: ID!) {
      user(id: $id) {
        id
        username
        lastName
        firstName
        email
      }
    }
  `, {
    variables: {
      id
    }
  }, token);

  return getUserDetails?.user;
}
