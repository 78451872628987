import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { getUserDetails } from '../../services/accounts/getUserDetails';
import { saveUserDetails } from '../../services/accounts/saveUserDetails';
import { authContext } from 'components/ProvideAuth/ProvideAuth';

export const Profile = () => {
  const { token } = useContext(authContext);
  const storedUserInfo = localStorage.getItem('inl-user');
  const userId = (storedUserInfo) ? JSON.parse(storedUserInfo).user : null;

  const [userMeta, setUserMeta] = useState<{
    username: string;
    firstName: string;
    lastName: string;
    email: string;
  }>({
    username: '',
    firstName: '',
    lastName: '',
    email: ''
  });

  const [userUpdate, setUserUpdate] = useState<{
    isLoading: boolean;
    errorMessage: string;
    pendingMessage: string;
  }>({
    isLoading: false,
    errorMessage: '',
    pendingMessage: ''
  });

  useEffect(() => {
    let cancelRequest = false;

    if (userId) {
      const fetchUserInfo = async () => {
        const userInfo = await getUserDetails(userId, token);
        if (userInfo !== null) {
          if (cancelRequest) return;
          const { username, firstName, lastName, email } = userInfo;
          setUserMeta({
            username, firstName, lastName, email
          });
        }
      }
      fetchUserInfo();
    }
    return () => { cancelRequest = true; };
  }, [userId, token]);

  const hasAllReqFields = () => {
    const reqFields = [firstName, lastName, email];
    const completedFields = reqFields.filter(field => field?.length);
    return reqFields.length === completedFields.length;
  };

  const handleSaveChanges = async () => {
    setUserUpdate({
      isLoading: true,
      errorMessage: '',
      pendingMessage: 'Saving user changes...'
    });

    try {
      const updatedUserMeta = {
        id: userId,
        ...userMeta
      };
      await saveUserDetails(updatedUserMeta, token);
      const d = new Date();
      setUserUpdate({
        ...userUpdate,
        isLoading: false,
        pendingMessage: `Saved: ${d.toLocaleDateString()} ${d.toLocaleTimeString()}`,
      });
    } catch (err) {
      const msg = err?.message.replace('&lt;strong&gt;Error&lt;/strong&gt;: ', 'ERROR: ');
      setUserUpdate({
        isLoading: false,
        errorMessage: msg,
        pendingMessage: ''
      });
    }
  };

  if (!token) return <Redirect to='/login' />;

  const { username, firstName, lastName, email } = userMeta;

  return (
    <div className={'View View__my-account'}>
      <h2>User Account</h2>
      <form>
        <div className="input-group username">
          <label>Username</label>
          <input value={username} disabled={true} />
        </div>
        <div className="input-group firstname">
          <label>First Name</label>
          <input
            value={firstName}
            onChange={({ target }) => setUserMeta({ ...userMeta, firstName: target.value })}
          />
        </div>
        <div className="input-group lastname">
          <label>Last Name</label>
          <input
            value={lastName}
            onChange={({ target }) => setUserMeta({ ...userMeta, lastName: target.value })}
          />
        </div>
        <div className="input-group email">
          <label>Contact Email</label>
          <input
            value={email}
            onChange={({ target }) => setUserMeta({ ...userMeta, email: target.value })}
          />
        </div>
      </form>

      {!!userUpdate.errorMessage && <div className="error">{userUpdate.errorMessage}</div>}
      {!!userUpdate.pendingMessage && <div style={{ textAlign: 'center' }}>{userUpdate.pendingMessage}</div>}

      <div className='cta cta--right_align'>
        <button
          className='primary'
          disabled={!hasAllReqFields() || userUpdate.isLoading}
          onClick={handleSaveChanges}
        >
          Save
        </button>
      </div>
    </div>
  );
};
