import React, { ReactNode } from 'react';
import ProvideAuthObject from 'types/ProvideAuthObject';
import useProvideAuth, { provideAuthInitProps } from '../../hooks/useProvideAuth';

interface Props {
  children: ReactNode
}

export const authContext = React.createContext<ProvideAuthObject>(provideAuthInitProps());

const ProvideAuth = ({ children }: Props) => {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={{ ...auth }}>
      { children }
    </authContext.Provider>
  );
}

export default ProvideAuth;
