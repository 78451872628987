import { useState } from 'react';
import { Link } from 'react-router-dom';
import { registerUser } from '../../services/auth/register';

enum PasswordCheckStrength {
  Short,
  Common,
  Weak,
  Ok,
  Strong,
};

export const Register = () => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [errorMessage, setErrorMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  
  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setErrorMsg('Password and Confirm Password do not match');
    } else {
      setErrorMsg('');
      setLoading(true);
      try {
        const user = await registerUser({
          username,
          password,
          email,
          firstName,
          lastName
        });
        if (user.id) {
          setRegistered(true);
        }
      } catch (err: any) {
        const msg = err?.message.replace('&lt;strong&gt;Error&lt;/strong&gt;: ', 'ERROR: ');
        setErrorMsg(msg);
        setLoading(false);
      }
    }
  };

  const checkPasswordStrength= (password: string) => {
    // Build up the strenth of our password
    let numberOfElements = 0;
    let minlength = 8;
    let commonPasswordPatterns = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;
    numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Lowercase letters
    numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Uppercase letters
    numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements;      // Numbers
    numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements;   // Special characters (inc. space)

    // Assume we have a poor password already
    let currentPasswordStrength = PasswordCheckStrength.Short;

    // Check then strenth of this password using some simple rules
    if (password === null || password.length < minlength) {
        currentPasswordStrength = PasswordCheckStrength.Short;
    } else if (commonPasswordPatterns.test(password)) {
        currentPasswordStrength = PasswordCheckStrength.Common;
    } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
        currentPasswordStrength = PasswordCheckStrength.Weak;
    } else if (numberOfElements === 3) {
        currentPasswordStrength = PasswordCheckStrength.Ok;
    } else {
        currentPasswordStrength = PasswordCheckStrength.Strong;
    }

    // Return the strength of this password
    return currentPasswordStrength;
}
  const handleConfirmPassword = async (value:string) => {
    setConfirmPassword(value);
    setErrorMsg('');
    if(checkPasswordStrength(value) === PasswordCheckStrength.Short)setErrorMsg("Password should be a min of 8 characters");
    if(checkPasswordStrength(value) === PasswordCheckStrength.Common)setErrorMsg("Password entered is a common guessable word.");
    if(checkPasswordStrength(value) === PasswordCheckStrength.Weak)setErrorMsg("The password you entered is too weak");    
    else if(password !== value) setErrorMsg("Password and Confirm Password do not match");
  };

  const handlePassword = async (value:string) => {
    setPassword(value);
    setErrorMsg('');
    if(checkPasswordStrength(value) === PasswordCheckStrength.Short)setErrorMsg("Password should be a min of 8 characters");
    if(checkPasswordStrength(value) === PasswordCheckStrength.Common)setErrorMsg("Password entered is a common guessable word.");
    if(checkPasswordStrength(value) === PasswordCheckStrength.Weak)setErrorMsg("The password you entered is too weak");    
    else if(confirmPassword!== '' && confirmPassword !== value) setErrorMsg("Password and Confirm Password do not match");
  };

  const hasAllReqFields = () => {
    const reqFields = [username, password, email, firstName, lastName];
    const completedFields = reqFields.filter(field => field?.length);
    return reqFields.length === completedFields.length;
  };

  const Form = (
    <>
      <h2>Register</h2>
      <form>
        <div className='input-group firstName'>
          <label>First Name</label>
          <input onChange={({ target}) => setFirstName(target.value)} />
        </div>
        <div className='input-group lastName'>
          <label>Last Name</label>
          <input onChange={({ target}) => setLastName(target.value)} />
        </div>
        <div className='input-group email'>
          <label>Email</label>
          <input onChange={({ target}) => setEmail(target.value)} />
        </div>
        <div className='input-group username'>
          <label>Username</label>
          <input onChange={({ target}) => setUserName(target.value)} />
        </div>
        <div className='input-group password'>
          <label>Password</label>
          <input
            type='password'
            autoComplete='new-password'
            onChange={({ target}) => handlePassword(target.value)}
          />
        </div>
        <div className='input-group password'>
          <label>Confirm Password</label>
          <input
            type='password'
            autoComplete='confirm-password'
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            onChange={({ target}) => handleConfirmPassword(target.value)}
          />
        </div>
        <div className='cta'>        
          <button
            className='primary'
            disabled={ !hasAllReqFields() || isLoading }
            onClick={ handleRegister }
          >
            Register
          </button>
        </div>
      </form>
    </>
  );
  
  const Registered = (
    <>
      <p>You're registration has been sent to the site admin for approval.</p>
      <Link to='/'>Back to Homepage</Link>
    </>
  );

  return (
    <div className='View View__register'>
      { !registered && Form }
      { !!registered && Registered }
      { !!errorMessage?.length && <div className='error'>{ errorMessage }</div> }      
    </div>
  );
}
