import { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import './Logout.scss';

export const Logout = () => {  
  const { token, logout } = useContext(authContext)
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    let cancelSetTimeout = false;

    // If logged in (auth token exists) ? logout 
    if (token) {
      logout();      
    }

    // No token after logout so redirect
    if (!token) {
      setTimeout(() => {
        if (cancelSetTimeout) return;
        setRedirect(true);
      }, 1000);
    }
    
    // set conditional so async setTimeout call does not cause a memory leak (error: set state on unmounted component)
    return () => { cancelSetTimeout = true; }
   }, [token, logout]);

  return (
    <div className='Logout'>  
      <div className='Logout__Message'>You have successfully logged out.</div>
      { redirect && <Redirect to='/' /> }
    </div>
  )
};
