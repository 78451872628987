/**
 * 
 * @param startDateStr string
 * @param endDateStr string
 * @returns string Formatted date 
 */
export const formatEventDateDisplay = (startDateStr: string, endDateStr: string): string => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();  

  const startMonthAndDate = startDateStr.slice(0, startDateStr.indexOf(','));

  if (startYear !== endYear) {
    return `${startDateStr} - ${endDateStr}`;
  }

  // ie: September 30 - October 1, 2021
  if (startMonth !== endMonth) {
    return `${startMonthAndDate} - ${endDateStr}`;
  }

  // ie: October 1 - 2, 2021
  return `${startMonthAndDate} - ${endDate.getDate()}, ${endDate.getFullYear()}`;
}