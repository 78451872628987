import { useEffect, useState, useContext } from 'react';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import { Link, Redirect } from 'react-router-dom';

export const Login = () => {
  const { isLoading, error, user, login } = useContext(authContext);

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMsg] = useState('');
  const [pendingMessage, setPendingMsg] = useState('');
  const [redirect, setRedirect] = useState(false);

  const roles = user?.roles.edges.map(role => role.node);
  const isPendingSubscriber = roles?.map(role => role.displayName).includes('pending_subscriber');

  useEffect(() => {
    if (error) {
      const msg = error?.message.replace('&lt;strong&gt;Error&lt;/strong&gt;: ', 'ERROR: ');
      setErrorMsg(msg);
    }

    if (isPendingSubscriber) {
      setPendingMsg(`Your membership is still pending approval.  Please try again later or contact the site admin.`);
    }

    if (user?.id && !isPendingSubscriber) {
      setRedirect(true);
    }
  }, [user, error, isPendingSubscriber]);

  const hasAllReqFields = () => {
    const reqFields = [username, password];
    const completedFields = reqFields.filter(field => field?.length);
    return reqFields.length === completedFields.length;
  };

  const handleLogin = async () => await login(username, password);

  return (
    <div className='View View__login Login'>
      <h2>Login</h2>
      <form>
        <div className="input-group username">
          <label>Username</label>
          <input onChange={({ target }) => setUserName(target.value)} />
        </div>

        <div className="input-group password">
          <label>Password</label>
          <input
            autoComplete="current-password"
            type={'password'}
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        <div className="cta">
          <Link className="register" to="/register">Register</Link>
          <button
            className={'primary'}
            disabled={!hasAllReqFields() || isLoading}
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </form>

      {!!errorMessage?.length && <div className="error">{errorMessage}</div>}

      {!!pendingMessage?.length && <div>{pendingMessage}</div>}      

      {/* Redirect to homepage after login */}
      {redirect && <Redirect to='/' />}
    </div>
  );
}
