import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { getPage } from 'services/page/getPage';
import { authContext } from 'components/ProvideAuth/ProvideAuth';
import './PageContent.scss';

const initPageContextProps = {
  eventDates: {
    startEventDate: '',
    endEventDate: '',
  }
}
export const PageContentContext = React.createContext<{
  eventDates: {
    startEventDate: string;
    endEventDate: string;
  }
}>({
  ...initPageContextProps
});

interface Props {
  page: string;
  noMarkup?: boolean;
  children?: ReactNode;
}

const PageContent = ({ page, children, noMarkup = false }: Props) => {
  const { token } = useContext(authContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | boolean>(false);
  const [markup, setMarkup] = useState<string>('');
  const [eventDatesInfo, setEventDatesInfo] = useState({ ...initPageContextProps })

  useEffect(() => {
    let cancelRequest = false;

    const getData = async () => {
      try {
        setIsLoading(true);
        const data = await getPage(page, token);
        const { content, eventDates } = data.page;

        if (cancelRequest) return;
        setIsLoading(false);

        if (content) {
          setMarkup(content);
        }

        setEventDatesInfo({ eventDates });
      } catch (err) {
        if (cancelRequest) return;
        const error = new Error(`${err}`);
        setIsLoading(false);
        setRequestError(error.message);
      }
    };

    getData();

    return () => { cancelRequest = true; };
  }, [token, page, noMarkup]);

  const renderError = requestError && (
    <div className={`View View__${page}`}>Error retrieving requested page.</div>
  );

  const hasMarkupSetHTML = !noMarkup && (
    <div className={`View View__${page}`} dangerouslySetInnerHTML={{ __html: markup }} />
  );

  const noMarkupRenderChildren = !!noMarkup && children;

  return (
    <PageContentContext.Provider value={{ ...eventDatesInfo }}>
      { isLoading && <div className="View View__Loading">Loading...</div> }
      { !isLoading && renderError }
      { !isLoading && hasMarkupSetHTML }
      { !isLoading && noMarkupRenderChildren }
    </PageContentContext.Provider>
  );
};

export default PageContent;
