import './Footer.scss';
import logo from 'assets/images/INL-Logo_Right-White.webp';

export const Footer = () => {
  return (
    <footer className='Footer'>
      <img
        src={logo}
        alt={'INL LOGO'}
      />
    </footer>
  );
}
