import { fetchAPI } from '../fetchAPI';

export async function getDraftPage(pageId: any, token: string) {
  return fetchAPI(`
    query DraftPage($id: ID!, $idType: PageIdType!, $asPreview: Boolean) {
      page(id: $id, idType: $idType, asPreview: $asPreview) {
        slug
        title
        content
      }
    }
  `, {
    variables: {
      id: pageId,
      idType: 'DATABASE_ID',
      asPreview: true
    }
  }, token);
};

