import { fetchAPI } from '../fetchAPI';

interface RegistrationInfoProps {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
}

export async function registerUser(registrationInfo: RegistrationInfoProps) {
  const apiCall = await fetchAPI(`
    mutation RegisterUser (
      $username: String!,
      $password: String!,
      $email: String!,
      $firstName: String,
      $lastName: String
    ){
      registerUser(
        input: {
            username: $username,
            password: $password,
            email: $email,
            firstName: $firstName,
            lastName: $lastName
        }) {
        user {
          id,
          name,
          username,
          email,
          jwtAuthToken
          jwtRefreshToken
        }
      }
    }
  `, {
    variables: {
      ...registrationInfo
    }
  });

  return apiCall.registerUser.user;
}
