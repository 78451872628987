const WP_API_URL = process.env.REACT_APP_WP_API_URL;
const API_URL = WP_API_URL ? WP_API_URL : '/wp/graphql';

export const fetchAPI = async (query: string, { variables }: any = {}, token?: string | null) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'User-Agent': '*'
  };

  if (token && token.length > 0) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query, variables })
  });

  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    const error = json.errors[0];
    throw new Error(error.message);
  }

  return json.data;
};
